html, body{
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;  
  margin: 0;
  padding: 0;
}
p{
  padding: 0;
  margin: 0;
}

button{
  background: #1D5BC0;
  color: white;
  border-radius: 7px;
  margin: 0;
  font-size: 14px;
  padding: 10px;
  border: none;
}

/* button:hover{
  background: #2458b8;
  transition: all 0.5s;
  border: none;
} */

.nav-link{
  margin: 0px 15px 0px 15px;
  color: black;
  font-weight: 600;
}

.nav-link.hover {
  color: red;
}
.nav-link.active {
  color: #1D5BC0;
}

::-webkit-scrollbar {
  display: none;
}

/* TEXT HIGHLIGHT  */
::selection {
  background: #82adff;
  color: #000;
}

/* FONT  */
@font-face{
  font-family: myFont;
  src:url('./Fonts/Garet-Book.otf');
}
*{
  font-family: myFont;
}

@font-face{
  font-family: myFontBold;
  src:url('./Fonts/Mont-HeavyDEMO.otf');
} 
h1, .fontSpecial{
  font-family: myFontBold;
}   

/* HERO MAIN DIV  */
.HomeHeroMainDiv{
  height: 100vh;
  width: 100vw;
  background-color: #d5faec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.Newdividiv{
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.HeroMainDiv{
  height: 85vh;
  width: 100vw;
  background-color: #d5faec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeroMainDivImage, .HeroMainDivImage2{
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100%;
  width: 40%;
  padding: 20px;
}

.buttonerdivv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.HeroMainDivText{
  display: flex;
  flex-direction: column;
  width: 70%;
}

.HeroMainDivText2{
  display: flex;
  flex-direction: column;
  width: 70%;
  background: rgba(255,255,255,0.7); 
}

.HeroMainDivText_Pets{
  display: flex; justify-content: center; flex-direction: column;
  align-items: flex-start; height: 100%; width: 40%; padding: 0; margin: 0;}

.RegistrationWhiteBox{
  width: 43vw;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px; 
  flex-direction: column;
}
.RegistrationWhiteBoxCheckout{
  width: 43vw;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px; 
  flex-direction: column;
  padding: 20px 15px;
  gap: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content{
  width: 200px;
  height: 170px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #edeef0;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid rgb(185, 184, 184);
  border-radius: 15px;
  transition: all .3s ease;
  cursor: pointer;
}

.content:hover{
  border-color: #fff;
  background: #d5faec;
}

.optionBox{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: row;
}
.optionBoxxy{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  flex-direction: row;
}

.Petty{
  display: flex;
}

.RegistrationFormColumn{
  width: 50%;
  /* height: 300px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column
}
.RegistrationFormColumnPage2{
  width: 50%;
  /* height: 300px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column
}

.ButtonofWhiteBox{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  margin-bottom: 20px;
}

.postnominal{
  font-weight: 900;
  font-size: 13px;
}
.custom-file-upload {
  background: rgb(231, 231, 231);
  color: black;
  font-weight: 900;
  border-radius: 5px;
  margin: 0;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px; 
  padding-bottom: 5px;
  font-size: 13px;
  border: 1px solid grey;
  cursor: pointer;
}

.custom-file-upload:hover{
  background: #dbdbdb;
  transition: all 0.5s;
}
.OnlyColumnPage2{
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.LogRegButton{
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 0;
  margin: 0;  
  justify-content: flex-start;
  align-items: center;
}



.HealthIssues {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #cbdbfa;
  width: 25%;
  color: black;
  border-radius: 5px;
  transition: background-position 0.5s ease, box-shadow 0.5s ease;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid #cbdbfa;
  transition: height 3s;
  transition-delay: 1s;
  padding: 5px;
}

.HealthIssues:hover {
  border: 2px solid #8db1f8;
}

.HealthIssues2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #faecc3;
  width: 400px;
  color: black;
  border-radius: 5px;
  transition: background-position 0.5s ease, box-shadow 0.5s ease;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid #faecc3;
  transition: height 3s;
  transition-delay: 1s;
  padding: 5px;
}

.HealthIssues2:hover {
  border: 2px solid #d5faec;
}



.dashboardmain{
  height: 100%; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff5db;
  flex-direction: column;
  width: calc(100vw - 250px);
  padding-bottom: 50px;
}

.divierwelcome{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 15px;
}

.divierwelcometext{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  padding: 15px;
  height: 100%;
  flex-direction: column; 
}

.divierwelcomeimage{ 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  padding: 15px;
  height: 100%;
}

.PatientDiv{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100px;
  border-bottom: 1px solid #c7c3c3;
  background-color: white;
  padding: 0px 20px 0px 20px;
}
.PatientDiv:hover{
  background: #d5faec;
}

.SubPatientDiv{
  display: flex;
  justify-content: flex-start; 
  gap: 10px;
  align-items: center;
  width: 100%;
}

.AppointmentCodeMain{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  width: 90%;
  height: 1000px;
  margin-top: 20px;
  gap: 20px;
}

.AppointmentCodeLeft{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: blue; */ 
  width: 25%;
  height: 1000px;
  flex-direction: column;
}

.AppointmentCodeRight{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  width: 75%;
  height: 1000px;
  flex-direction: column;
  padding: 15px;
  border-radius: 15px;
}

.hidernav{
  text-decoration: none;
  height: 40px;
  font-size: 14px;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  text-align: center;
  margin: 20px 0px 0px 0px;
}

.YourPetsDiv{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;  
  padding: 20px;
}

.PetDetailDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #e8e8e8;
  width: 300px;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.PetDetailDiv:hover{
  transform: scale(1.01);
}

.ConcernDiv{
  display: flex; 
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  background: #f7f7f7;
  /* height: 100%; */
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.ConcernDiv:hover{
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  /* background-color: #efefef; */
}

.PetRegDiv{
  display:flex;
  justify-content:flex-start;
  align-items: center;
  background:white;
  width:40%;
  overflow: auto;
  flex-direction:column;
  border-radius: 10px;
}

.DocProfDivLeft{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  height: 100vh;
  background: green; 
}

.DocProfDivRight{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
  height: 100vh;
  background: white;
  flex-direction: column
}

.DocProfDivMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  gap: 10px
}

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.cloud {
  animation: floatUpDown 6s ease-in-out infinite; /* Adjust the duration (4s) as needed */
}

.HoverImage{
  width: 40vw;
  border-radius: 500px;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.MaxWidthImage{
  width: 40vw;
  border-radius: 500px;
}

.HoverImage:hover{
  /* transition: 1s; */
  /* transform: scale(1.01); */
  filter: grayscale(0%);  
}

.MeetTheTeam{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 7px;
  gap: 20px;
}

.meettheTeamDivier{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #d5faec;
  width: 50%;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.TiffDiv{
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: left
}

.JohnDiv{
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: right
}

.PackageHolder{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  flex-direction: column;
  gap: 15px;
}

.PackageHolderText{
  width: 50%;
  display: flex;
  padding: 20px;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AboutImgHolder{
  width: 57%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 20px
}

.AboutTextHolder{
  width: 43%;
  display: flex;
  padding: 20px;
  text-align: left;
  flex-direction: column;
}

.AboutImgTextDiv{
  margin-top: 50px;
  width: 100vw;
  background-color: #d5faec;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.fontsizee{
  width: 80%;
}

.dashboardvideocall{
  height: 100%; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #d5faec;
  flex-direction: column;
  width: 100vw;
  background-image: url('../Homepage/Texture.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.PackageType {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
  background: white;
  width: 80%;
  border-radius: 10px;
  flex-direction: column;
}

.custom-table {
  border-collapse: collapse;
}

.custom-table td {
  padding: 5px;
}

.TrinityStripUC {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px 40px 20px;
  gap: 30px;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.TrinityStripElementUC {
  background: #fff;
  width: 30%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.TrinityStripElementUC:hover {
  transform: translateY(-5px);
}


.TrinityStripHome {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center; /* Center items */
  padding: 40px 20px;
}

.TrinityStripHomeElement {
  background: #d5faec;
  flex: 1 1 300px; /* Ensure all items have equal width and can shrink/grow */
  max-width: 320px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 30px 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.TrinityStripHomeElement:hover {
  transform: translateY(-5px); /* Lift up the div on hover */
}



.CourseCover {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 10px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 56%; /* This creates the height proportional to the width */
  position: relative; /* Needed to position the content absolutely */
}

.CourseCover p {
  position: absolute; /* Absolutely position the content within the div */
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  background: #1D5BC0;
  color: white;
  font-size: 8px;
  padding: 2px 10px 2px 10px;
  border-radius: 5px 0px 3px 0px;
}

.DPhiderReverse{
  display: none;
}

.imagediv{
  width: 60px;
  display: flex;
  border-radius: 10px;
} 

.mainContacter{
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.contactdiv{
  gap: 5px;
  padding: 20px;
  width: 500px;
  height: 570px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contactdiv2{
  border: 0.5px solid #1D5BC0;
  gap: 5px;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* CAROUSEL CSS ON HOME PAGE  */
.carousel-inner {
  height: 100%;
}
/* Ensure carousel controls are visible */
.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
  height: 50px;
  width: 50px;
  /* background-color: #1D5BC0; */
  border-radius: 10px;
  color: white;
}

/* Position previous button */
.carousel-control-prev {
  position: absolute;
  left: 0;
  top: 50%;
}

/* Position next button */
.carousel-control-next {
  position: absolute;
  right: 0;
  top: 50%;
}
.dashboardvideocall{
  height: 100%; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.parallax-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
}

.parallax-item:first-child {
  background: url('../../1_MediaAssets/MainPages/BlogsCover.jpg');
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  height: 80vh;
  filter: brightness(150%);
}


.parallax-item2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-attachment: fixed;
  height: 70vh;
  position: relative;
}

.parallax-item2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 139, 0.6); /* Dark blue with 50% opacity */
  z-index: 1;
}

.parallax-item2 > * {
  position: relative;
  z-index: 2;
}


.parallax-item3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-attachment: fixed;
  height: 70vh;
  position: relative;
}

.parallax-item3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark blue with 50% opacity */
  z-index: 1;
}

.parallax-item3 > * {
  position: relative;
  z-index: 2;
}



.CoursePageMainDiv{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  padding: 10px;
  gap: 20px;
}

.CoursePageLeftDiv {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: black;
  width: 65%;
  flex-direction: column;
  gap: 10px;
  border-radius: 15px;
}

.CoursePageRightDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: white;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px 20px;
  background-image: url("../../1_MediaAssets/Homepage/Texture.png");
  background-position: center;
  background-repeat: no-repeat 
}

.BlogTextArea {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  padding: 30px;
  gap: 10px;
  flex-direction: column;
}

.BlogTextArea2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 30px;
  gap: 10px;
  flex-direction: column;
}

.BlogSection {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.BlogSectionRightDiv {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 70%;
  height: 100%;
  background: #F5F5F5;
  border-radius: 0px 10px 10px 0px;
}

.ContactWidgetMainMainDiv { 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}




.meet-the-team {
  text-align: center;
  padding: 50px;
  background: white;
  width: 100vw;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust this to space-around or space-between */
  gap: 20px;
}

.team-member {
  flex: 1 1 200px;
  max-width: 200px;
  text-align: center;
  margin: 10px;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.team-member h2 {
  margin: 10px 0 5px;
  font-size: 1.2em;
}

.team-member p {
  margin: 0;
  font-size: 1em;
}

@media (max-width: 600px) {
  .team-member {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.faculty-image {
  width: 100%;
  max-width: 300px; /* Set a max-width for the images */
  height: auto;
  border-radius: 10px;
  margin-bottom: 3px;
}
.faculty-card {
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.3s ease;
  cursor: pointer;
  max-width: 300px;
}
.faculty-card:hover {
  transform: translateY(-5px); 
}


.partner-image {
  width: 100%;
  max-width: 300px; /* Set a max-width for the images */
  height: auto;
  border-radius: 10px;
  margin-bottom: 3px;
}
.partner-card {
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  max-width: 300px;
}
.partner-card:hover {
  transform: translateY(-5px); 
}


.course-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 80%;
  padding: 15px;
  box-sizing: border-box;
}

.course-card {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.3s ease;
  cursor: pointer;
}
.course-card:hover {
  transform: translateY(-5px); /* Lift up the div on hover */
}


@media (max-width: 1024px) {
  .course-section {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
  }
}

@media (max-width: 600px) {
  .course-section {
      grid-template-columns: 1fr;
      width: 100%;
  }
}

.checkout-form {
  width: 43vw;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkout-form h2 {
  text-align: center;
  color: #333;
}

.card-element-container {
  padding: 10px; 
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1D5BC0;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #aab7c4;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #2b6bd1;
}

.error-message, .success-message {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.error-message {
  color: #e74c3c;
  background-color: #fce4e4;
}

.success-message {
  color: #2ecc71;
  background-color: #e4fcef;
}

.cta {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  /* border: 2px solid white; */
  background-color: rgb(0, 0, 0); /* Set background color to black */
  color: #fff;
  transition: width 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden; /* Ensure contents don't overflow */
  position: relative; /* Position relative to adjust inner elements */
}

.cta:hover {
  width: 170px;
  background-color: rgb(255, 255, 255); /* Set background color to black */
  color: black;
}

.cta .iii {
  opacity: 1;
  transition: opacity 0.5s;
}

.cta:hover .iii {
  opacity: 0;
}

.cta .button-text {
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
  text-align: center;
  position: absolute; /* Position absolute to overlap with the icon */
  color: black;
}

.cta:hover .button-text {
  opacity: 1;
}

.MainWrapper{
 display: flex; width: 100%; margin-top: 10px; gap: 10px;
}

.ChildWrapper{
  display: flex;
  flex-direction: column;
  width: 50%;
}


.MenuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid lightgrey;
  min-height: 160px;
  background-color: #1D5BC0;
  color: white;
  font-size: 14px;
  gap: 10px;
  background-image: url('../../1_MediaAssets/Homepage/Texture3Light.png');
}

.MenuItem:hover {
  border: 1px solid #8FF2CC;
  color: #8FF2CC
}


@media screen and (max-width: 1500px) { 
  .meettheTeamDivier{
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .MeetTheTeam{
    flex-direction: column;
  }
}


@media screen and (max-width: 800px) { 
  .TrinityStripElementUC{
    width: 100%;
  }
}

@media screen and (max-width: 1000px) { 
  .ContactWidgetMainMainDiv { 
    justify-content: center;
    padding: 0px;
  }
  .CoursePageMainDiv{
    flex-direction: column;
  }
  .CoursePageLeftDiv{
    width: 100%;
  }
  .CoursePageRightDiv{
    width: 100%;
  }
  .BlogTextArea {
    width: 100%;
    padding: 0px;
  }
  .BlogTextArea2 {
    width: 100%;
    padding: 0px;
  }
  
  .BlogSection {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .BlogSectionRightDiv {
    width: 100%;
  }
  .HeroMainDivPets{
    display: none;
  }
  .HeroMainDiv{
    height: 85vh;
    flex-direction: column;
  }
  .HomeHeroMainDiv{
    height: 100%;
    flex-direction: column;
  }
  .Newdividiv{
    height: 100%;
    flex-direction: column;
  }
  .HeroMainDivImage{
    width: 100%;
    margin-top: 40px;
    padding: 0px 20px 0px 20px;
  }
  .HeroMainDivImage2{
    display: none;
  }
  .HeroMainDivText_Pets{
    width: 100%;
    padding: 0px 20px;
    text-align: center;
    align-items: center;
  }
  .HeroMainDivText{
    width: 100%;
    text-align: center;
  }
  .HeroMainDivText2{
    width: 85%;
    text-align: center;
    background: rgba(255,255,255,0.9);
  }
  .RegistrationWhiteBox{
    width: 90vw;
    margin-top: 20px;
  }
  .RegistrationWhiteBoxCheckout{
    width: 90vw;
  }
  .checkout-form {
    width: 90vw;
    margin-bottom: 40px;
  }
  .optionBox{
    flex-direction: column;
    margin-top: 25px; 
    /* height: 350px; */
    margin-bottom: 18px;
  }
  .optionBoxxy{
    flex-direction: column;
  }
  .Petty{
    display: none;
  }
  .RegistrationFormColumn{
    width: 100%;
  }
  .RegistrationFormColumnPage2{
    width: 100%;
  }
  .postnominal{
    margin-top: 10px;
  }
  .OnlyColumnPage2{
    padding: 0px;
  }
  .LogRegButton{
    flex-direction: column;
  }
  .HealthIssues{
    width: 45%;
  }  
  
  .HealthIssues2{
    width: 90%;
  }  
  .dashboardmain{
    width: 100vw;
    padding-top: 30px;
  }
  .divierwelcome{
    flex-direction: column;
  }
  .divierwelcometext{
    width: 100%;
  }
  .divierwelcomeimage{
    width: 100%;
  }
  .DPhider{
    display: none;
  }
  .DPhiderReverse{
    display:flex;
  }
  .SubPatientDiv{
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    padding-left: 15px;
  }
  .AppointmentCodeMain{
    flex-direction: column;
  }
  .AppointmentCodeLeft{
    width: 100%;
  }
  .AppointmentCodeRight{
    width: 100%;
  }
  .hidernav{
    display: none;
  }
  .YourPetsDiv{
    align-items: left;
    justify-content: center;
  }
  .PetDetailDiv{
    height: 200px;
    width: 100%;
  }
  .PetRegDiv{
    width:90%;
  }

  .DocProfDivLeft{
    width: 100%;
  }
  
  .DocProfDivRight{
    width: 100%;
  }
  
  .DocProfDivMain {
    flex-direction: column;
  }
  .TiffDiv{
    text-align: center;
  }
  .JohnDiv{
    text-align: center;
  }
  .meettheTeamDivier{
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  }
  .AboutImgHolder{
    width: 100%;
  }
  .AboutTextHolder{
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  .AboutImgTextDiv{
    flex-direction: column;
  }
  .fontsizee{
    width: 100%;
    text-align: center;
  }
  .PackageHolder{
    width: 100%;
  }
  .PackageType{
    width: 95vw;
  }
  .PackageHolderText{
    width: 100%;
  }
  .custom-table {
    text-align: center;
  }
  .MaxWidthImage{
    width: 80vw;
  }
  .TrinityStrip {
    flex-direction: column;
  }
  .imagediv{
    display: none;
  }
  .footer-widget-two__title-box{
    margin-top: 30px;
  }
  .row{
    padding: 0px 20px 0px 20px;
  }
  .contactdiv{
    width: 95%;
  }
  .contactdiv2{
    width: 95%;
  }
  .mainContacter{
    padding-top: 0px;
  }

  .MainWrapper{
    flex-direction: column;
   }
   
   .ChildWrapper{
     width: 100%;
   }

   .TrinityStripHome {
    width: 100vw;
  }
}

.splide__arrow:hover {
  background: rgb(126, 216, 255);

}

.splide__arrow--next:hover{
  background: rgb(126, 216, 255);
}

.splide__arrow--prev:hover{
  background: rgb(126, 216, 255);
}
